import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
})
export class TextInputComponent {

  /**
   * The type of the input field
   */
  @Input() public type: string; // e.g. password

  /**
   * The displayed text as a two way binding
   */
  @Input()
  public get text() {
    return this._text;
  }
  @Output() public textChange: EventEmitter<string> = new EventEmitter<string>();
  public set text(value: string) {
    this._text = value;
    this.textChange.emit(this.text);
  }
  private _text: string = '';


  /**
   * The text displayed as label
   */
  @Input() public labelText: string = '';

  /**
   * Flag if the label is always on top of the input
   * e.g. good, when type is date or time
   */
  @Input() public showLabelAlways: boolean = false;
}
